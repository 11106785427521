<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goBack" />
            {{ $t('productAvailability.goodsReceipt') }}
          </h1>
        </div>
      </div>
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="isDetail ? {} : rules"
      :class="{ info: isDetail }"
    >
      <!-- Detail -->
      <a-card :title="'Detail ' + $t('productAvailability.goodsReceipt')" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="'No. ' + $t('productAvailability.goodsReceipt')" prop="number">
              <a-input
                v-model.trim="form.number"
                size="large"
                autocomplete="off"
                :placeholder="'No. ' + $t('productAvailability.goodsReceipt')"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.grDate')" prop="date">
              <a-date-picker
                v-model="form.date"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('productAvailability.grDate')"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.reference')" prop="reference_number">
              <a-input
                v-model.trim="form.reference_number"
                size="large"
                autocomplete="off"
                :placeholder="$t('productAvailability.reference')"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('sideBar.warehouse')" prop="warehouse_id" class="mb-0">
              <a-select
                v-model="form.warehouse_id"
                size="large"
                class="select-antd-default"
                :placeholder="$t('sideBar.warehouse')"
                disabled
              >
                <a-select-option
                  v-for="item in warehouseList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.vendorName')" prop="supplier_id" class="mb-0">
              <a-input
                v-model.trim="form.supplier_id"
                size="large"
                autocomplete="off"
                :placeholder="$t('productAvailability.vendorName')"
                allow-clear
              />
              <!-- <a-select
                v-model="form.supplier_id"
                size="large"
                class="select-antd-default"
                :placeholder="$t('productAvailability.vendorName')"
                :disabled="isDetail"
              >
                <a-select-option v-for="level in 4" :key="level" :disabled="level != currTier">
                  {{ level }}
                </a-select-option>
              </a-select> -->
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <!-- Detail Item Produk -->
      <a-card title="Detail Item Produk" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="24">
            <div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex">
                  <a-button
                    type="primary"
                    @click="addItem"
                  >
                    Tambah Item
                  </a-button>
                  <!-- TODO: import bulk items -->
                  <a-button
                    class="ml-2"
                    type="primary"
                    @click="showModalBulkItemImport = true"
                  >
                    Bulk Item Import
                  </a-button>
                </div>
                <div>Total SKU: <b>{{ computedValidSKU || 0 }}</b></div>
              </div>
              <a-table
                :columns="columns"
                :row-key="(record, index) => index"
                :data-source="itemList"
                :loading="isLoading"
                :pagination="false"
                :scroll="{ y: 330 }"
              >
                <template slot="action1" slot-scope="text, record">
                  <div
                    @click="onDeleteItem(record)"
                  >
                    <TrashIcon style="color: #E00000; cursor:pointer" />
                  </div>
                </template>
                <template slot="catalog_id" slot-scope="text, record">
                  <a-select
                    v-model="record.catalog_id"
                    size="large"
                    class="select-antd-default"
                    style="width: 100%"
                    placeholder="Produk"
                    show-search
                    :label-in-value="false"
                    :not-found-content="fetching ? undefined : null"
                    :filter-option="false"
                    @change="getProductUOM($event, record)"
                    @blur="handleResetSearch"
                    @search="handleSearch"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option
                      v-for="item in inventories"
                      :key="item.id"
                      :value="`${item.id}.${item.product_id}`"
                      :disabled="selectedSKU.includes(item.sku)"
                    >
                      <div class="d-flex flex-column product-select">
                        <div>{{ item?.title }}</div>
                        <div class="sku-desc">
                          {{ 'SKU : ' + ellipsisText(item?.sku) }}
                        </div>
                      </div>
                    </a-select-option>
                  </a-select>
                </template>
                <template slot="quantity" slot-scope="text, record">
                  <a-input
                    v-model="record.quantity"
                    :disabled="!record.catalog_id"
                    size="large"
                    type="number"
                    :min="0"
                    autocomplete="off"
                    placeholder="Qty"
                    @change="onChangeQuantity($event.target.value, record)"
                    @keypress="numberOnly"
                  />
                </template>
                <template slot="uom" slot-scope="text, record">
                  <a-spin :spinning="fetching">
                    <a-select
                      v-model="record.uom"
                      size="large"
                      class="select-antd-default"
                      placeholder="UoM"
                      :disabled="isDetail || !record.catalog_id"
                    >
                      <a-select-option v-for="level in record.levels" :key="level">
                        {{ level }}
                      </a-select-option>
                    </a-select>
                  </a-spin>
                </template>
                <template slot="batch_number" slot-scope="text, record">
                  <a-input
                    v-model="record.batch_number"
                    :disabled="!record.catalog_id"
                    size="large"
                    autocomplete="off"
                    placeholder="No. Batch"
                    allow-clear
                  />
                </template>
                <template slot="expired_date" slot-scope="text, record">
                  <a-date-picker
                    v-model="record.expired_date"
                    :disabled="!record.catalog_id"
                    size="large"
                    class="w-100"
                    :format="dateFormat"
                    placeholder="Expired Date"
                  />
                </template>
                <template slot="stock_type" slot-scope="text, record">
                  <a-select
                    v-model="record.stock_type"
                    size="large"
                    class="select-antd-default"
                    placeholder="Tipe Stok"
                    :disabled="isDetail || !record.catalog_id"
                  >
                    <a-select-option
                      v-for="item in stockList"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </template>
                <template slot="base_uom" slot-scope="text, record">
                  <a-input
                    v-model="record.base_uom"
                    size="large"
                    autocomplete="off"
                    placeholder="Base UoM"
                    allow-clear
                    disabled
                  />
                </template>
                <template slot="base_quantity" slot-scope="text, record">
                  <a-input
                    v-model="record.base_quantity"
                    size="large"
                    autocomplete="off"
                    placeholder="Base Qty"
                    allow-clear
                    disabled
                  />
                </template>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <div v-if="!isDetail" class="discount-footer">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="goBack"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :loading="isLoading"
          @click="onSubmitGr"
        >
          {{ $route.query.discount_id && !isDuplicate ? $t('utils.edit') : $t('utils.save') }}
        </a-button>
      </div>
    </a-form-model>

    <ModalBulkItemImport
      :is-loading="isLoading"
      :visible="showModalBulkItemImport"
      :on-close="() => showModalBulkItemImport = false"
      @handleSubmitModal="onNormalizeData"
    />
    <ModalFeedbackAfterImport
      :revised-data="listRevisedBulkItemImport"
      :is-loading="isLoading"
      :visible="showModalFeedbackAfterImport"
      :on-close="() => showModalFeedbackAfterImport = false"
    />
  </div>
</template>

<script>
import { columnsCreateGR } from '@/data/columns'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import { getCatalogListGR, getUom } from '@/api/inventory'
import debounce from 'lodash/debounce'
import ModalBulkItemImport from '@/views/inventory/goods-receipt/component/ModalBulkItemImport.vue'
import ModalFeedbackAfterImport from '@/views/inventory/goods-receipt/component/ModalFeedbackAfterImport.vue'
import { createGoodsReceipt } from '@/api/good-receive'

export default {
  name: 'CreateDiscount',
  components: {
    TrashIcon,
    ModalBulkItemImport,
    ModalFeedbackAfterImport,
  },
  beforeRouteLeave(to, from, next) {
    // if (this.$route.query.discount_id) {
    //   delete this.$route.query.discount_id
    // }
    next()
  },
  setup() {
    const { numberOnly, toNumber } = useInputNumber()
    const { parseISODate } = useDateTime()
    return { numberOnly, toNumber, parseISODate }
  },
  data() {
    return {
      defaultTier: 1,
      readyTier: 1,
      currTier: 1,
      currQuota: 0,
      sellerId: [],
      idPromo: -1,
      dateFormat: 'DD MMM YYYY HH:mm',
      columns: columnsCreateGR,
      isLoading: false,
      isDetail: false,
      fetching: false,
      endOpen: false,
      visible: false,
      areaAll: false,
      distributorAll: false,
      categoryAll: false,
      customerAll: false,
      itemList: [],
      warehouseList: [],
      inventories: [],
      rules: {},
      initVal: {},
      search: '',
      overlayStyle: {
        width: '100px',
      },
      params: {
        page: 1,
        size: 1000,
      },
      form: {
        number: '',
        reference_number: '',
        supplier_id: '',
        date: null,
        warehouse_id: '',
      },
      stockList: [
        {
          id: 'AV',
          label: 'Available',
        },
        {
          id: 'QR',
          label: 'Quarantine',
        },
        {
          id: 'DMG',
          label: 'Damage',
        },
        {
          id: 'WIP',
          label: 'Work In Progress',
        },
      ],
      showModalBulkItemImport: false,
      listRevisedBulkItemImport: [],
      showModalFeedbackAfterImport: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    business() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
    isPrinciple() {
      return ['business owner', 'supervisor', 'store operator', 'principle'].includes(this.$store.state.user.role_name?.toLowerCase())
    },
    selectedSKU() {
      return this.itemList.map((obj2) => obj2.sku)
    },
    computedValidSKU() {
      return this.itemList.filter((obj) => obj.catalog_id)?.length
    },
  },
  watch: {
    businessId() {
      this.init()
    },
    search: {
      deep: true,
      immediate: true,
      handler: function () {
        // if (value) {
        this.fetchInventory()
        // }
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init: debounce(function() {
      this.getWarehouseList()
      this.fetchInventory()
    }, 500),
    filterOption(input, option) {
      const itemTitle = option.componentOptions.children[0].text;
      const itemSKU = option.componentOptions.children[2].text;
      const keyword = input.toLowerCase();
        
      return (
        itemTitle.toLowerCase().indexOf(keyword) !== -1 ||
        itemSKU.toLowerCase().indexOf(keyword) !== -1
      )
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 29 ? `${text.substring(0, 26)}...` : text
    },
    async onSubmitGr() {
      this.isLoading = true
      const filter = this.warehouseList.filter(v => {return v.value === this.form.warehouse_id})
      const payload = {
        ...this.form,
        business_id: this.businessId,
        reference_id: '',
        reference_type: 'ID',
        supplier_title: this.form.supplier_id,
        items: this.itemList.map(v => {
          const data = {
            ...v,
            catalog_id: v.catalog_id.split('.')[0],
            item_reference_id: '',
            quantity: Number(v.quantity),
            base_quantity: Number(v.base_quantity),
            levels: undefined,
            units: undefined,
          }
          return data
        }),
      }

      await createGoodsReceipt({
        param: filter[0].wms_categories,
        data: payload,
      })
      .then(() => {
        this.isLoading = false
        this.$notification.success({
          message: 'Data Berhasil Disimpan',
        })
        this.goBack()
      })
      .catch((err) => {
        console.error({err})
        this.$notification.error({
          message: err?.response?.data?.message || 'Data Gagal Disimpan, Coba Lagi.',
        })
        this.isLoading = false
      })
    },
    async fetchInventory() {
      this.isLoading = true
      const bodyValue = {
        business_id: this.businessId,
        warehouse_id: this.$route.query.warehouseId,
        search: this.search,
        page: 1,
        limit: 20,
      }
      await getCatalogListGR(bodyValue)
        .then(({ data: { data: response } }) => {
          this.inventories = response
        })
        .catch(err => {
          this.inventories = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    handleSearch: debounce(function(e) {
      this.search = e
    }, 500),
    handleResetSearch() {
      this.search = ''
    },
    async getWarehouseList() {
      return await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          business_id: this.businessId,
        })
        .then(response => {
          this.warehouseList = response
          this.form.warehouse_id = this.$route.query.warehouseId
        })
        .catch(err => {
          console.error(err)
          return err
        })
    },
    async getProductUOM(value, record) {
      const findInventory = this.inventories.find(item => {
        return item.id === value.split('.')[0]
      })
      record.catalog_title = findInventory?.title || ''
      record.sku = findInventory?.sku || ''
      
      this.fetching = true
      await getUom({
        id: value.split('.')[1],
        params: {
          business_id: this.businessId,
        },
      })
      .then(({ data: { data: response } }) => {
        let levels = []
        response.units.forEach((item, index) => {
          levels.push(item.unit)
          record.units[item.unit] = {
            numerator: item.numerator,
            denumerator: item.denumerator,
          }

          if(index == 0) record.uom = item.unit
          if(item.numerator == 1 && item.denumerator == 1) record.base_uom = item.unit
        })
        record.levels = levels
      })
      .catch(() => {
        record.uom = ''
        record.base_uom = ''
        record.levels = []
        record.units = {}
      })
      .finally(() => {
        this.fetching = false
      })
    },
    onChangeQuantity(event, record) {
      record.base_quantity = +event * +record.units[record.uom].numerator
    },
    onDeleteItem(record) {
      const filter = this.itemList.filter(v => {return v.item_reference_id !== record.item_reference_id})
      const temp = filter.map((v, i) => {
        const data = {
          ...v,
          item_reference_id: typeof v.item_reference_id === 'string' ? v.item_reference_id : i + 1,
        }
        return data
      })
      this.itemList = temp
    },
    onNormalizeData(importedData) {
      this.isLoading = true
      this.listRevisedBulkItemImport = []
      const normalizeData = []
      importedData.map((obj) => {
        const status = []
        const newObj = Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key.toLowerCase(), val]),
        );
        const isDuplicatedSKU = normalizeData.find((obj2) => obj2.sku === newObj.sku)
        if (isDuplicatedSKU) {
          return
        }
        if (!/^\d+$/.test(newObj.quantity) || !newObj?.uom || !newObj?.sku) {
          status.push('data_not_valid')
        }
        if (status.length) {
          this.listRevisedBulkItemImport.push({
            ...newObj,
            status: status,
          })
          return
        }
        normalizeData.push(newObj)
      })
      this.onSubmitBulkItemImport(normalizeData)
    },
    onSubmitBulkItemImport(importedData) {
      const promises = []
      const tempItemList = this.itemList
      importedData.map((obj) => {
        const isDuplicatedSKU = tempItemList.find((obj2) => obj2.sku === obj.sku)
        if (isDuplicatedSKU) {
          return
        }
        const bodyValue = {
          business_id: this.businessId,
          warehouse_id: this.$route.query.warehouseId,
          search: obj.sku,
          page: 1,
          limit: 20,
        }
        promises.push(
          getCatalogListGR(bodyValue),
        )
      })

      Promise.all(promises)
        .then(async (res) => {
          await Promise.all(res.map(async (obj, index) => {
            const { data, total_row } = obj.data
            if (total_row > 0) {
              const findSpecificCatalog = data.find((item) => item.sku === importedData[index].sku)
              if (findSpecificCatalog) {
                const selecetedCatalog = findSpecificCatalog
                const newRecord = {
                  item_reference_id: tempItemList.length + index,
                  catalog_id: `${selecetedCatalog.id}.${selecetedCatalog.product_id}`,
                  catalog_title: selecetedCatalog.title,
                  quantity: importedData[index].quantity,
                  uom: importedData[index].uom,
                  sku: importedData[index].sku,
                  batch_number: '',
                  expired_date: null,
                  stock_type: 'AV',
                  base_quantity: '',
                  base_uom: '',
                  serial_number: '',
                  levels: [],
                  units: {},
                }
                const { data: { data: uomLevels } } = await getUom({
                  id: selecetedCatalog.product_id,
                  params: {
                    business_id: this.businessId,
                  },
                })
                console.log(uomLevels)
                if (uomLevels) {
                  let levels = []
                  uomLevels.units.forEach((item, index) => {
                    levels.push(item.unit)
                    newRecord.units[item.unit] = {
                      numerator: item.numerator,
                      denumerator: item.denumerator,
                    }
                    if(item.numerator == 1 && item.denumerator == 1) newRecord.base_uom = item.unit
                  })
                  newRecord.levels = levels
                } else {
                  newRecord.uom = ''
                  newRecord.base_uom = ''
                  newRecord.levels = []
                  newRecord.units = {}
                }
                const isValidUOM = newRecord.levels.find((obj) => obj === newRecord.uom)
                if (isValidUOM) {
                  const isExistOnInventories = this.inventories.find((obj) => obj.id === selecetedCatalog.id)
                  if (!isExistOnInventories) {
                    this.inventories.push(selecetedCatalog)
                  }
                  this.itemList.push(newRecord)
                  this.onChangeQuantity(`${newRecord.quantity}`, newRecord)
                } else {
                  this.listRevisedBulkItemImport.push({
                    ...importedData[index],
                    status: ['uom_not_valid'],
                  })
                }
              } else {
                this.listRevisedBulkItemImport.push({
                  ...importedData[index],
                  status: ['sku_not_found'],
                })
              }
            } else {
              this.listRevisedBulkItemImport.push({
                ...importedData[index],
                status: ['sku_not_found'],
              })
            }
          }))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.showModalBulkItemImport = false
          this.isLoading = false
          if (this.listRevisedBulkItemImport.length) {
            this.showModalFeedbackAfterImport = true
            this.$notification.warning({
              message: 'Beberapa data tidak valid',
            })
          } else {
            this.$notification.success({
              message: 'Semua Data Berhasil di Import',
            })
          }
        })
    },
    addItem() {
     const arr = this.itemList
     const temp = {
        item_reference_id: arr.length + 1,
        catalog_id: '',
        catalog_title: '',
        quantity: '',
        uom: '',
        sku: '',
        batch_number: '',
        expired_date: null,
        stock_type: 'AV',
        base_quantity: '',
        base_uom: '',
        serial_number: '',
        levels: [],
        units: {},
     }
     arr.push(temp)
     this.itemList = arr
    },
    goBack() {
      this.$router.back({
        path: '/inventory/goods-receipt',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
@import './create.module.scss';
</style>
<style lang="scss" scoped>
.ant-select-lg .ant-select-selection--single .product-select {
  white-space: nowrap !important;
}
.product-select {
  white-space: pre-wrap !important;
  .sku-desc {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
